import React, { useEffect, useContext, useMemo} from 'react';
import { Box, Grid } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { LanguageContext } from '../../App';

interface FaseProps {
  fase: ProtocoloFase
  faseIndex: number
  vacinas: Vacina[]
}


export default function ProtocoloFase(props: FaseProps) {
  const { fase: { faseD, itens, faseCodigo }, faseIndex, vacinas} = props
  const [searchParams] = useSearchParams();
  const { translations, setLanguage } = useContext(LanguageContext);
  
  const itemTipoEnum = useMemo(() => translations ? [
    "medicamento", "implante", "remover implante", "iatf"
  ] : [], [translations]);

  useEffect(() => {
    const languageParam = searchParams.get('language') || 'pt'
    setLanguage(languageParam)
  }, [searchParams, setLanguage]);

  return (
    <Grid id={`fase-${faseCodigo}`} item container xs={12}>
      <Box sx={{ width: "100%", backgroundColor: "#efefef", border: "1px solid #e7ebee", borderRadius: "3px"} }>
        <Grid item container xs={12} px={1} justifyContent="space-between" alignItems="center" py={1}>
          <Grid className="protocolo-fase__header" item xs={6} >
            <span>
              {`${ translations["lbl.react.fase"] } ${faseIndex + 1}`}
            </span>
          </Grid>
          <Grid item container justifyContent="start" xs={6} alignItems="center">
            <Grid item className="fase-title">
              <span>
                {`D ${faseD}`} 
              </span>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ width: "100%", backgroundColor: "#f7f7f7", border: "1px solid #f7f7f7", borderRadius: "3px"} }>
        <Grid container item xs={12} px={3} py={2} mb={3}>
          <Grid container px={3} borderBottom={"1px solid #efefef;"} pb={2}>
            <Grid className="protocolo-item__title" item xs={3} pl={0}>
              <span>{ `${translations["lbl.react.protocolo.tipo"]}` }</span>
            </Grid>
            <Grid className="protocolo-item__title" item xs={4}>
              <span>{ translations["lbl.react.protocolo.descricao"] }</span>
            </Grid>
            <Grid className="protocolo-item__title" item xs={4}>
              <span>{ `${translations["lbl.react.protocolo.produto"]}` }</span>
            </Grid>
            <Grid className="protocolo-item__title" item xs={1}>
              <span>{ `${translations["lbl.react.protocolo.quant"]}` }</span>
            </Grid>
          </Grid>
          <Grid container px={3} >
            {
              itens.map((item: ProtocoloItem, index: number) => (
                <Grid item container xs={12} mt={2} key={item.itemCodigo} className="fase-card__item">
                  <Grid item container xs={3} pr={4} flexWrap="nowrap">
                    <Grid item xs>
                        <span>{ `${itemTipoEnum[parseInt(item.itemTipo)]}` }</span>
                    </Grid>
                  </Grid>
                  { !["iatf"].includes(itemTipoEnum[parseInt(item.itemTipo)]) &&
                    <Grid item xs={4} pr={4}>
                      <span>{ `${item.itemDesc}` }</span>
                    </Grid>
                  }
                  { !["iatf", "remover implante"].includes(itemTipoEnum[parseInt(item.itemTipo)]) &&
                    <Grid item xs={4} pr={4}>
                      <span>{ 
                        `${vacinas?.find((vacina: Vacina) => vacina.vacCodigo === parseInt(item.vacCodigo))?.vacDescricao.toLowerCase()}
                          (${vacinas?.find((vacina: Vacina) => vacina.vacCodigo === parseInt(item.vacCodigo))?.vacMedida.toLowerCase()})
                        ` 
                      }</span>
                    </Grid>
                    
                  }
                  { !["iatf", "remover implante"].includes(itemTipoEnum[parseInt(item.itemTipo)]) &&
                    <Grid item xs={1} className="item-qtd">
                      <span>{ `${item.itemQtd.toString().replace(".",",")}` }</span>
                    </Grid>
                  }
                </Grid>
              ))
            }
          </Grid>
        </Grid>
      </Box>
    </Grid>
  )
}