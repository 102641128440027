import { createBrowserRouter } from 'react-router-dom';
import IndicadoresNascimentos from './pages/IndicadoresNascimentos';
import IndicadoresPrenhez from './pages/IndicadoresPrenhez';
import Descarte from './pages/Descarte';
import Error404 from './pages/Error404';
import IndicadoresDesmame from './pages/IndicadoresDesmame';
import Protocolo from './pages/Protocolo';
import NovoProtocolo from './pages/NovoProtocolo';
import AplicarProtocolo from './pages/AplicarProtocolo';

const routes = createBrowserRouter([
  {
    path: "/",
    children: [
      {
        path: "indicadores",
        children: [
          {
            path: "nascimentos",
            element: <IndicadoresNascimentos />
          },
          {
            path: "prenhez",
            element: <IndicadoresPrenhez />
          },
          {
            path: "desmame",
            element: <IndicadoresDesmame />
          }
        ]
      },
      {
        path: "cadastros",
        children: [
          {
            path: "descarte",
            element: <Descarte />
          },
          {
            path: "protocolo",
            element: <NovoProtocolo />
          },
          {
            path: "uso_protocolo",
            element: <AplicarProtocolo />
          },
        ]
      },
      {
        path: "listar",
        children: [
          {
            path: "protocolo",
            element: <Protocolo />
          },
        ]
      },
      {
        path: "*",
        element: <Error404 />,
      },
      {
        path: "/",
        element: <Error404 />,
      }
    ],
  }
]);

export default routes;