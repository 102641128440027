import React, { useEffect, useState, useContext, useCallback } from 'react';
import { Button, Grid, Tooltip } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import Loading from '../components/Loading';
import Tabs from '../components/Tabs';
import { LanguageContext } from '../App';
import Table from '../components/Table';
import fieldsTables from '../utils/fieldsTables';
import showColumns from '../utils/showColumns';
import clockIcon from '../assets/imgs/clockIcon.svg'
import WarningIcon from '@mui/icons-material/Warning';
import InfoIcon from '@mui/icons-material/Info';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DataPickerCustom from '../components/RangeDataPicker';
import SelectCustom from '../components/SelectCustom';
import { getProtocolos } from '../apis/apiProtocolo';
import { postAplicacaoProtocolo } from '../apis/apiUsoProtocolo';
import FaseCard from '../components/protocolo/FaseCard';
import { getVacinas } from '../apis/apiVacina';
import { getAnimaisSelecionadosProtocolo } from '../apis/apiAnimais';
import dayjs from 'dayjs';
require('dayjs/locale/pt-br')
require('dayjs/locale/es')

interface ComboProtocoloItem {
  value: string,
  valueKey: string | number;
}

function AplicarProtocolo() {
  const [loading, setLoading] = useState<boolean>(false)
  const [selectedTab, setSelectedTab] = useState<string>("");
  const [searchParams] = useSearchParams();
  const [dataProtocolo, setDataProtocolo] = useState<Date | string>(new Date())
  const [selectedProtocolo, setSelectedProtocolo] = useState<string>('')
  const [protocolo, setProtocolo] = useState<Protocolo>()
  const [fasesProtocolo, setFasesProtocolo] = useState<FasesProtocoloPayload[]>([])
  const [comboProtocolo, setComboProtocolo] = useState<ComboProtocoloItem[]>([])
  const [protocolos, setProtocolos] = useState<Protocolo[]>([])
  const [vacinas, setVacinas] = useState<Vacina[]>([])
  const [animaisSelecionados, setAnimaisSelecionados] = useState<Animal[]>([])
  const [lotesDiferentes, setLotesDiferentes] = useState<string[]>([])
  const { translations, setLanguage, language } = useContext(LanguageContext);

  const handleChangeTab = (event: React.SyntheticEvent, value: string) => {
    setSelectedTab(value);
  };

  const datePickerHandleChangeSingle = (date: Date) => {
    setDataProtocolo(date)
    if (protocolo) {
      updateProtocoloFases(protocolo)
    }
  };

  const datePickerHandleClear = () => {
    setDataProtocolo(new Date())
    if (protocolo) {
      updateProtocoloFases(protocolo)
    }
  };

  const handleItemInputChange = (event: any) => {
    setSelectedProtocolo(event.target.value)
    const protocoloSelecionado = protocolos.find((protocolo: Protocolo) => protocolo.ptlCodigo === parseInt(event.target.value))
    setProtocolo(protocoloSelecionado)
    if (protocoloSelecionado) {
      updateProtocoloFases(protocoloSelecionado)
    }
  };

  const updateProtocoloFases = (protocolo: Protocolo) => {
    const mappedFases = protocolo.fases.sort((a: ProtocoloFase, b: ProtocoloFase) => a.faseD - b.faseD).map((fase: ProtocoloFase) => ({
      faseD: fase.faseD,
      faseCodigo: fase.faseCodigo,
      aptlfDataPrevista: dayjs(dataProtocolo).add(fase.faseD + (protocolo.fases[0].faseD * -1), 'day').format("DD/MM/YYYY")
    }))

    setFasesProtocolo(mappedFases)
  }

  const handleAplicarProtocolo = useCallback(async () => {
    setLoading(true)
    const proCodigo = searchParams.get('pro_codigo');

    const params = {
      proCodigo: proCodigo,
      ptlCodigo: protocolo?.ptlCodigo,
      aptlData: dayjs(dataProtocolo).format("DD/MM/YYYY"),
      animais: animaisSelecionados.map((animal: Animal) => ({
        aniCodigo: animal.aniCodigo,
        proCodigo: proCodigo,
      })),
      fases: fasesProtocolo
    }

    const token = searchParams.get('token')

    const result = await postAplicacaoProtocolo(params, token);

    if (result.status === 200) window.top!.location.href = `${process.env.REACT_APP_BASE_MANEJO}/adm/reproduction?acao=listAptas&filt_protocolo=1`
    setLoading(false)

  }, [animaisSelecionados, dataProtocolo, fasesProtocolo, protocolo, searchParams]);

  const handleGetProtocolos = useCallback(async (filters: FiltersType) => {
    setLoading(true)
    const proCodigo = searchParams.get('pro_codigo');

    const params = {
      proCodigo: proCodigo,
      dataIni: "",
      dataFim: "",
      lotCodigo: "",
      status: true
    }

    const token = searchParams.get('token')

    const result = await getProtocolos(params, token);
    const mappedResult: ComboProtocoloItem[] = result.data.map((protocolo: Protocolo) => ({
      value: protocolo.ptlNome,
      valueKey: `${protocolo.ptlCodigo}`
    }))
    setComboProtocolo(mappedResult);
    setProtocolos(result.data)

    setLoading(false)
    
  }, [searchParams]);

  const handleGetAnimais = useCallback(async () => {
    setLoading(true)
    const proCodigo = searchParams.get('pro_codigo');

    const params = {
      proCodigo: proCodigo,
      aniCodigos: searchParams.get('ani_codigos')
    }

    
    const token = searchParams.get('token')
    
    const result = await getAnimaisSelecionadosProtocolo(params, token);
    const lotesDiferentes = result.data.filter((animal: Animal) => animal.lotCodigo === "" || animal.lotCodigo !== result.data[0].lotCodigo).map((animal: Animal) => animal.lotCodigo)
    setLotesDiferentes(lotesDiferentes)
    setAnimaisSelecionados(result.data)

    setLoading(false)
    
  }, [searchParams]);

  const handleGetVacinas = useCallback(async () => {

    const proCodigo = searchParams.get('pro_codigo');

    const token = searchParams.get('token')

    const result = await getVacinas(proCodigo, token);

    setVacinas(result)
    
  }, [searchParams]);

  useEffect(() => {
    setLoading(true)
    const languageParam = searchParams.get('language') || 'pt'
    const initialTab = searchParams.get('indicador') || "iniciar_protocolos"
    setLanguage(languageParam)
    setLoading(false)
    setSelectedTab(initialTab)
  }, [searchParams, setLanguage]);

  useEffect(() => {

    handleGetProtocolos({
      startDate: '',
      endDate: '',
    });

    handleGetVacinas()

    handleGetAnimais()

  }, [handleGetProtocolos, handleGetVacinas, handleGetAnimais]);

  return (
    <Grid container justifyContent="center">
      { translations &&
        <Grid item xs={12}>
          <div className="page-body">
            <span className="page-title pointer" onClick={() => window.history.back()}>
              { `${translations["lbl.react.breadcrumb.reproducao.femeas.vazias"]} > ` }
            </span>
            <span className="page-title cinza">{ translations["lbl.react.breadcrumb.iniciar.protocolo"] }</span>
            <Grid container rowSpacing={2} justifyContent="center">
              <Grid item xs={6}>
                { translations["lbl.react.dados"] }
              </Grid>
              <Grid item xs={6} textAlign={"end"} flexDirection={"row"}>
                <Button onClick={() => window.history.back()} className="btn-secundario btn-voltar" variant="contained"><ArrowBackIcon /> { translations["lbl.react.voltar.para.lista.de.animais"] }</Button>
              </Grid>
              <Grid item xs={12}>
                { selectedTab &&
                  <Tabs handleChange={handleChangeTab} value={selectedTab} labels={[{ label: `${translations["lbl.react.selecionados"]} (${animaisSelecionados.length})`, name: "selecionados" }, { label: translations["lbl.react.iniciando.protocolo"], name: "iniciar_protocolos" }]}/>
                }
              </Grid>
              { loading === false ?
                <Grid item xs={12} pt={0}>
                  {selectedTab === "selecionados" &&
                    <Grid item xs={12}>
                      <Grid container item xs={12} pb={2} mb={3}>
                        <Table
                          columns={fieldsTables(translations).animaisProtocolo}
                          rows={animaisSelecionados}
                          idColumn='idVisual'
                          sortColumn='idVisual'
                          hasCheckbox={false}
                          tableTitle=""
                          tableSubTitle=""
                          name="animaisProtocolo"
                          printButton={false}
                          showColumns={showColumns.animaisProtocolo}
                          handleCloseTable={() => {}}
                          border={false}
                        />
                      </Grid>
                    </Grid>
                  }
                  {selectedTab === "iniciar_protocolos" &&
                    <Grid item xs={12}>
                      <Grid container item xs={12} pb={2} mb={3}>
                        { lotesDiferentes.length > 0 &&
                          <Grid container item xs={12} marginTop={2} className='banner-alert' flexDirection={"column"}>
                            <WarningIcon className='banner-alert_icon' color='warning'/>
                            <p><strong>{translations["lbl.react.atencao"]}</strong></p>
                            <p>{ translations["lbl.react.warning.existem.animais.lotes.diferentes"] }</p>
                          </Grid>
                        }
                        <Grid item container xs={6} mt={1}>
                          <span className="input-label">{ `${translations["lbl.react.selecione.protocolo"]}` }</span>
                          <SelectCustom
                            placeholder={translations["lbl.react.protocolo"]}
                            handleChange={handleItemInputChange}
                            items={comboProtocolo}
                            selectedItem={selectedProtocolo}
                            name="itemTipo"
                          />
                        </Grid>
                        <Grid item container xs={12} mt={1}>
                          <Grid item container xs={4} flexWrap={"nowrap"}>
                            <Grid item xs>
                              <span className="input-label">{ `${translations["lbl.react.data.primeira.fase"]}` }</span>
                              <DataPickerCustom
                                onlyMonths={false}
                                dateFormat={"dd/MM/yyyy"}
                                handleChange={datePickerHandleChangeSingle}
                                handleClear={datePickerHandleClear}
                                handleClickOutside={() => {}}
                                startDate={dataProtocolo ? new Date(dataProtocolo) : null}
                                endDate={null}
                                selectRange={false}
                                placeholder=""
                                limitMaxDate={true}
                                showWeek={true}
                              />  
                            </Grid>
                            <Grid item ml={1} pt={1} alignSelf={"end"}>
                              <Tooltip title={translations["lbl.react.tooltip.marca.inicio.primeira.fase"]}><InfoIcon className='info-icon' fontSize='small'/></Tooltip>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      { protocolo !== undefined &&
                        <Grid container item xs={12} pb={2} mb={3}>
                          <Grid container item xs={12}  justifyContent="end" mb={2} >
                            <Grid container item xs={9}  justifyContent="space-between">
                              <Grid item alignSelf={"end"} >
                                <span className="protocolo-title">{ protocolo?.ptlNome }</span>
                              </Grid>
                              <Grid item textAlign={"end"} flexDirection={"row"}>
                                <Button onClick={handleAplicarProtocolo} startIcon={<img src={clockIcon} alt="novo cadastro icon" />} className="btn-primario" variant="contained">{ translations["lbl.react.iniciar.protocolo"] }</Button>
                              </Grid>
                            </Grid>
                          </Grid>
                          {
                            protocolo.fases.sort((a: ProtocoloFase, b: ProtocoloFase) => a.faseD - b.faseD).map((fase: ProtocoloFase, index: number) => (
                              <Grid container item xs={12} columnSpacing={2} key={fase.faseCodigo}>
                                <Grid container item xs={3} borderRight={"1px solid #90A4AE"}>
                                  <Grid container item xs={8} display={"block"} textAlign={"end"} mt={6}>
                                    <Grid item xs={12}>
                                      <span className="input-label">{ translations["lbl.react.fase"] }</span>
                                    </Grid>
                                    <Grid item xs={12}>
                                      <span className="input-label">
                                        {
                                          `${dayjs(dataProtocolo).add(fase.faseD + (protocolo.fases[0].faseD * -1), 'day').locale(language === 'pt' ? 'pt-br' : language).format('ddd, DD MMM')} de
                                          ${dayjs(dataProtocolo).add(fase.faseD + (protocolo.fases[0].faseD * -1), 'day').locale(language === 'pt' ? 'pt-br' : language).format('YYYY')}`
                                        }
                                      </span>
                                    </Grid>
                                  </Grid>
                                  <Grid container item xs={4} flexDirection={"column"} flexWrap={"nowrap"} pl={1}>
                                    <div className={`fase-line__top ${index === 0 ? 'first-line' : ''}`}></div>
                                    <div className='fase-circle'>
                                      <span>{index + 1}</span>
                                    </div>
                                    { (index + 1) < protocolo.fases.length && <div className={`fase-line__bottom ${(index + 1) < protocolo.fases.length ? '' : 'last-line'}`}></div> }
                                  </Grid>
                                </Grid>
                                <Grid container item xs={9} pb={(index + 1) === protocolo.fases.length ? 0 : 2}>
                                  <FaseCard
                                    key={index}
                                    fase={fase}
                                    faseIndex={index}
                                    vacinas={vacinas}
                                  />
                                </Grid>
                              </Grid>
                            ))
                          }
                        </Grid>
                      }
                    </Grid>
                  }
                </Grid>
                :
                <Loading />
              }
            </Grid>
          </div>
        </Grid>
      }
    </Grid>
  );
}

export default AplicarProtocolo;